import './App.css';

import logo from './logo.svg';
import search from './search.svg';
import comedogenic from './comedogenic.png';
import comedogenic_qm from './comedogenic_qm.png';

import {Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import axios from 'axios';

import Header from "./Header";
import PinkTextHeadline from "./PinkTextHeadline";
import IngredientList from "./IngredientList";
import PinkTextList from "./PinkTextList";
import DescriptionText from "./DescriptionText";
import MobileHeader from "./MobileHeader";
import MobilePinkTextHeadline from "./MobilePinkTextHeadline";
import MobileIngredientList from "./MobileIngredientList";

const menuItems = [
    {name: "home", image: comedogenic},
    //{name: "about", image: comedogenic_qm},
    //{name: "contact", image: comedogenic}
];

const mobileMenuItems = [
    {name: "home", image: comedogenic},
    {name: "about", image: comedogenic_qm},
    //{name: "contact", image: comedogenic}
];

function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 480);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
}

const MobileLayout = ({ query, handleChange, handleKeyDown, handleButtonClick, items }) => (
    <div className="App">
        <div>
            <MobileHeader logo={logo} title="SKIIN"/>
            <hr style={styles.line}/>
            <nav style={styles.mobileMenuContainer}>
                {mobileMenuItems.map((item, index) => (
                    <Link key={index} to={`/${item.name.toLowerCase()}`} style={styles.mobileMenuItem}>
                        {/* Render the image */}
                        <img src={item.image} alt={item.name} style={styles.mobileMenuImage}/>
                        {/* Render the name of the menu item */}
                        {item.name}
                    </Link>
                ))}
            </nav>

            <div style={styles.mobilePage}>
                <hr style={styles.line}/>
                <div style={styles.mobileInputContainer}>
                    <MobilePinkTextHeadline text="Analyse ingredients"></MobilePinkTextHeadline>
                    <input className="centered"
                           type="text"
                           value={query}
                           onChange={handleChange}
                           onKeyDown={handleKeyDown}
                           placeholder="Add ingredients..."
                           style={styles.mobileInput}/>
                    <img src={search} alt="Search" onClick={handleButtonClick} style={styles.mobileRunButton}/>
                    <MobileIngredientList items={items}/>
                </div>
            </div>
        </div>
    </div>
);

const DesktopLayout = ({query, handleChange, handleKeyDown, handleButtonClick, items}) => (
    <div className="App">
        <div>
            <Header logo={logo} title="SKIIN"/>
            <div style={styles.page}>
                <hr style={styles.line}/>
                <div>
                    <nav style={styles.menuContainer}>
                        {menuItems.map((item, index) => (
                            <Link key={index} to={`/${item.name.toLowerCase()}`} style={styles.menuItem}>
                                {/* Render the image */}
                                <img src={item.image} alt={item.name} style={styles.menuImage}/>
                                {/* Render the name of the menu item */}
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                </div>

                <div style={styles.searchSide}>
                    <PinkTextHeadline text="Analyse ingredients"></PinkTextHeadline>
                    <div>
                        <input className="centered"
                               type="text"
                               value={query}
                               onChange={handleChange}
                               onKeyDown={handleKeyDown}
                               placeholder="Add ingredients..."
                               style={styles.input}/>
                        <img src={search} alt="Search" onClick={handleButtonClick} style={styles.runButton}/>
                    </div>

                    <PinkTextList items={items}></PinkTextList>
                    <IngredientList items={items}/>
                </div>

                <div style={styles.aboutSide}>
                    <DescriptionText></DescriptionText>
                </div>
            </div>
        </div>
    </div>
);

const Home = () => {
    const isMobile = useIsMobile();

    const [items, setItems] = useState([]);
    const [query, setQuery] = useState('');
    const formattedIngredients = formatIngredients(query);

    let ingredients = []

    const handleChange = (event) => {
        setQuery(event.target.value);
    };
    const handleButtonClick = () => {
        ingredients = formattedIngredients
        console.log(ingredients.length)
        searchField();
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleButtonClick();
        }
    };
    const searchField = async () => {
        try {
            const response = await axios.post('https://skiin.site/api/data', ingredients, {
                headers: {'Content-Type': 'application/json'}
            });
            if (Array.isArray(response.data)) {
                setItems([]);
                response.data.forEach((item, index) => {
                    console.log(`Item ${index}: ${item}`);
                    addIngredient(item)
                });
            } else {
                console.error('The response is not an array:', response);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server responded with:', error.response.status, error.response.data);
            }
        }
    };
    const addIngredient = (ingredient) => {
        if (!ingredient) return; // Guard clause for empty text
        setItems((prevItems) => [...prevItems,
            {
                id: prevItems.length + 1,
                name: ingredient.name,
                category: ingredient.category,
                comrating: ingredient.comrating,
                cas: ingredient.cas,
                ec: ingredient.ec,
                source: ingredient.source,
                image: comedogenic
            }]);
        setQuery("");
    };

    return (
        <div className="App" style={isMobile ? styles.mobilePage : styles.page}>
            {isMobile
                ?
                <MobileLayout
                    query={query}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    handleButtonClick={handleButtonClick}
                    items={items}/>
                :
                <DesktopLayout
                    query={query}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    handleButtonClick={handleButtonClick}
                    items={items}
                />
            }
        </div>
    );
}

function formatIngredients(ingredientsString) {
    const ingredientsArray = ingredientsString.split(/[(),/]+/).filter(item => item !== "");
    return ingredientsArray.map(ingredient => {
        const mappedIng = ingredient.trim();
        console.log(mappedIng);
        return mappedIng;
    });
}

const styles = {
    page: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
    },
    searchSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: '#C9F1F8',
    },
    aboutSide: {
        width: '100%',
        height: '100%',
        background: '#DDFBFF',
    },
    line: {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0 0px',
    },
    input: {
        width: '80%',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        outline: 'none',
        color: '#5ac8de'
    },
    runButton: {
        width: '50px',
        height: '50px',
        background: '#fff',
        cursor: 'pointer',
        marginBottom: '-20px',
        marginLeft: '-62px'
    },
    menuContainer: {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginLeft: 'auto',
    },
    menuItem: {
        width: '200px',
        height: '100px',
        color: '#5ac8de',
        display: 'flex',
        alignItems: 'center',
        background: '#C9F1F8',
        marginLeft: '50px',
        borderBottomLeftRadius: '100px',
        borderTopLeftRadius: '100px',
    },
    menuImage: {
        width: '100px',
        height: '100px',
        marginRight: '25px',
        marginBottom: '5px',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
    },
    mobilePage: {
        height: '100%',
        position: 'absolute',
        background: '#C9F1F8',
    },
    mobileMenuContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '15px',
        marginBottom: '15px',
        marginLeft: '15px',
    },
    mobileMenuItem: {
        textDecoration: 'none',
        color: '#5ac8de',
        width: '70px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mobileMenuImage: {
        width: '45px',
        height: '45px',
        marginBottom: '5px',
    },
    mobileInput: {
        width: '75vw',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        outline: 'none',
        color: '#5ac8de',
    },
    mobileInputContainer: {
        width: '100vw',
        height: '100vw',
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px',
        marginLeft: '25px',
        background: '#C9F1F8',
    },
    mobileRunButton: {
        width: '50px',
        height: '50px',
        background: '#fff',
        cursor: 'pointer',
        marginTop: '70px',
        marginRight: '75px',
        right: '0px',
        top: '0px',
        position: 'absolute',
    },
}

export default Home;
