import MobileHeader from "./MobileHeader";
import logo from "./logo.svg";
import {Link} from "react-router-dom";
import comedogenic from "./comedogenic.png";
import comedogenic_qm from "./comedogenic_qm.png";
import MobileDescriptionText from "./MobileDescriptionText";

const mobileMenuItems = [
    {name: "home", image: comedogenic},
    {name: "about", image: comedogenic_qm},
    //{name: "contact", image: comedogenic}
];

const About = () => (
    <div className="App">
        <div style={stylesMobile.mobilePage}>
            <MobileHeader logo={logo} title="SKIIN"/>
            <hr style={stylesMobile.line}/>
            <nav style={stylesMobile.mobileMenuContainer}>
                {mobileMenuItems.map((item, index) => (
                    <Link key={index} to={`/${item.name.toLowerCase()}`} style={stylesMobile.mobileMenuItem}>
                        {/* Render the image */}
                        <img src={item.image} alt={item.name} style={stylesMobile.mobileMenuImage}/>
                        {/* Render the name of the menu item */}
                        {item.name}
                    </Link>
                ))}
            </nav>
            <hr style={stylesMobile.line}/>
            <div>
                <MobileDescriptionText></MobileDescriptionText>
            </div>
        </div>
    </div>
);

const stylesMobile = {
    line: {
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0 0px',
    },
    mobilePage: {
        height: '100%',
        position: 'absolute',
        background: '#C9F1F8',
    },
    mobileMenuContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '15px',
        marginBottom: '15px',
        marginLeft: '15px', // Fixes left alignment
    },
    mobileMenuItem: {
        textDecoration: 'none',
        color: '#5ac8de',
        width: '70px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mobileMenuImage: {
        width: '45px',
        height: '45px',
        marginBottom: '5px',
    },
}

export default About;