// Header.js
import React from 'react';

const MobileHeader = ({logo, title}) => {

    return (
        <header style={styles.header}>
            <div style={styles.headerContainer}>
                <img src={logo} style={styles.logo}/>
                <h1 style={styles.title}>{title}</h1>
            </div>
        </header>
    );
};

const styles = {
    header: {
        backgroundColor: '#DDFBFF',
        color: '#5ac8de',
        paddingLeft: '25px',
        textAlign: 'start',
        minHeight: '25px',
        width: '100vw',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        width: '100%',
    },
    logo: {
        display: 'flex',
        width: '30px',
        height: '30px',
        objectFit: 'contain',
    },
    title: {
        fontSize: '1.3rem',
        display: 'flex',
        width: '25px',
        height: '25px',
        objectFit: 'contain',
    }
};

export default MobileHeader;
