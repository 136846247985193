import React from 'react';
import one from './one.png';
import two from './two.png';
import three from './three.png';
import four from './four.png';
import five from './five.png';

const IngredientList = ({items}) => {
    return (
        <div>
            {items.length > 0 ? (items.map((item) => (
                <div style={styles.container}>
                    <p style={styles.text}>{item.name}</p>
                    {item.comrating === "1" ? (
                        <img src={one} style={styles.comrating}></img>
                    ) : item.comrating === "2" ? (
                        <img src={two} style={styles.comrating}></img>
                    ) : item.comrating === "3" ? (
                        <img src={three} style={styles.comrating}></img>
                    ) : item.comrating === "4" ? (
                        <img src={four} style={styles.comrating}></img>
                    ) : item.comrating === "5" ? (
                        <img src={five} style={styles.comrating}></img>
                    ) : item.comrating === "1-2" ? (
                        <img src={one} style={styles.comrating}></img>
                    ) : item.comrating === "2-3" ? (
                        <img src={two} style={styles.comrating}></img>
                    ) : item.comrating === "3-4" ? (
                        <img src={three} style={styles.comrating}></img>
                    ) : item.comrating === "4-5" ? (
                        <img src={four} style={styles.comrating}></img>
                    ) : (
                        <p></p>
                    )}
                </div>
            ))) : (
                <div>
                    <p style={styles.text}></p>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        width: '85%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '50px',
        justifyContent: 'space-between'
    },
    text: {
        marginTop: '0px',
        fontSize: '1rem',
        color: '#5ac8de',
    },
    comrating: {
        width: '105px',
        height: '15px',
        src: one
    }
};

export default IngredientList;
